import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/formularioaumentaraforo",
        name: "aumentaraforo",
        component: () => import("@/views/aforos/FormularioAumentarAforo.vue"),
        meta: {
          pageTitle: "Aumentar Aforo",
          middleware: "auth",
        },
      },
      {
        path: "/formulariodisminuiraforo",
        name: "disminuiraforo",
        component: () => import("@/views/aforos/FormularioDisminuirAforo.vue"),
        meta: {
          pageTitle: "Disminuir Aforo",
          middleware: "auth",
        },
      },
      {
        path: "/formulariohabilitarestadosreimpresion",
        name: "formulariohabilitarestadosreimpresion",
        component: () => import("@/views/reimpresionboletas/FormularioHabilitarEstadosReimpresion.vue"),
        meta: {
          pageTitle: "Habilitar Reimprimir Venta",
          middleware: "auth", 
        },
      },
      {
        path: "/formularioreversarventas",
        name: "formularioreversarventas",
        component: () => import("@/views/reversarventas/FormularioReversarVentas.vue"),
        meta: {
          pageTitle: "Reversar Ventas",
          middleware: "auth", 
        },
      },
      {
        path: "/formulariocambiarpreciolocalidad",
        name: "formulariocambiarpreciolocalidad",
        component: () => import("@/views/localidad/FormularioCambiarPrecioLocalidad.vue"),
        meta: {
          pageTitle: "Cambiar Precio Localidades",
          middleware: "auth",
        },
      },
      {
        path: "/formulariocambiarprecioboletapalco",
        name: "formulariocambiarprecioboletapalco",
        component: () => import("@/views/localidad/FormularioCambiarPrecioBoletaPalco.vue"),
        meta: {
          pageTitle: "Cambiar Precio Boleta o Palco",
          middleware: "auth",
        },
      },
      {
        path: "/formularioreenviarventaboletaspalcos",
        name: "formularioreenviarventaboletaspalcos",
        component: () => import("@/views/ventas/FormularioReenviarVentaBoletasPalcos.vue"),
        meta: {
          pageTitle: "Reenviar Venta de  Boleta o Palco",
          middleware: "auth", 
        },
      },
      {
        path: "/eventosActivos",
        name: "eventosActivos",
        component: () => import("@/views/eventos/EventosActivos.vue"),
        meta: {
          pageTitle: "Eventos Activos",
          breadcrumbs: ["eventosActivos"],
          middleware: "auth",
        },
      },
      {
        path: "/eliminarevento",
        name: "eliminarevento",
        component: () => import("@/views/eventos/eliminarevento.vue"),
        meta: {
          pageTitle: "Eliminar Evento",
          breadcrumbs: ["eventosActivos"],
          middleware: "auth",
        },
      },
      {
        path: "/confirmareliminar/:id",
        name: "confirmareliminar",
        component: () => import("@/views/eventos/confirmareliminar.vue"),
        meta: {
          pageTitle: "Eliminar Evento",
          breadcrumbs: ["eventosActivos"],
          middleware: "auth",
        },
      },
      {
        path: "/mapearEvento/:id",
        name: "mapearevento",
        component: () => import("@/views/eventos/mapearevento.vue"),
        meta: {
          pageTitle: "Mapear Evento",
          breadcrumbs: ["eventosActivos"],
          middleware: "auth",
        },
      },
      {
        path: "/descargarQR",
        name: "descargarQR",
        component: () => import("@/views/puestos/DescargarQR.vue"),
        meta: {
          pageTitle: "Descarga de códigos QR",
          breadcrumbs: ["descargarQR"],
          middleware: "auth",
        },
      },
       
      {
        path: "/editArtistasEvento/:id",
        name: "editArtistasEvento",
        component: () => import("@/views/eventos/EditArtistasEvento.vue"),
        meta: {
          pageTitle: "Artistas Eventos",
          breadcrumbs: ["eventosActivos","editArtistasEvento"],
          middleware: "auth",
        },
      },
      {
        path: "/editEvento/:id",
        name: "editEvento",
        component: () => import("@/views/eventos/EditEvento.vue"),
        meta: {
          pageTitle: "Editar Evento",
          breadcrumbs: ["eventosActivos","editEvento"],
          middleware: "auth",
        },
      },
      {
        path: "/addPuestosPalco",
        name: "addPuestosPalco",
        component: () => import("@/views/palco/AdicionarPuestosPalco.vue"),
        meta: {
          pageTitle: "Adicionar Puestos",
          breadcrumbs: ["addPuestosPalco"],
          middleware: "auth",
        },
      },
      {
        path: "/palcosindividuales",
        name: "palcosindividuales",
        component: () => import("@/views/palco/Palcosindividuales.vue"),
        meta: {
          pageTitle: "Pasar a Palcos Individuales",
          breadcrumbs: ["palcosindividuales"],
          middleware: "auth",
        },
      },
      {
        path: "/imagenEvento/:id",
        name: "imagenEvento",
        component: () => import("@/views/eventos/EditImagenEvento.vue"),
        meta: {
          pageTitle: "Editar Imagen",
          breadcrumbs: ["eventosActivos","imagenEvento"],
          middleware: "auth",
        },
      },
      {
        path: "/cambioEstadoBoletas",
        name: "cambioEstadoBoletas",
        component: () => import("@/views/boletas/CambiarEstados.vue"),
        meta: {
          pageTitle: "Cambiar Estado",
          breadcrumbs: ["eventosActivos","imagenEvento"],
          middleware: "auth",
        },
      },
      {
        path: "/editCondiciones/:id",
        name: "editCondiciones",
        component: () => import("@/views/eventos/EditCondicionesLista.vue"),
        meta: {
          pageTitle: "Editar Condiciones",
          breadcrumbs: ["editCondiciones"],
          middleware: "auth",
        },
      },
      {
        path: "/crearPalco",
        name: "crearPalco",
        component: () => import("@/views/palco/CrearPalco.vue"),
        meta: {
          pageTitle: "Crear Palco",
          breadcrumbs: ["crearPalco"],
          middleware: "auth",
        },
      },
      {
        path: "/localidadesEventos/:id",
        name: "localidadesEventos",
        component: () => import("@/views/localidad/LocalidadesEventos.vue"),
        meta: {
          pageTitle: "Localidades Eventos",
          breadcrumbs: ["localidadesEventos"],
          middleware: "auth",
        },
      },
      {
        path: "/controlacceso",
        name: "controlacceso",
        component: () => import("@/views/informes/controlacceso.vue"),
        meta: {
          pageTitle: "Control de Acceso",
          middleware: "auth",
        },
      },
      {
        path: "/ventasevento",
        name: "ventasevento",
        component: () => import("@/views/informes/ventasevento.vue"),
        meta: {
          pageTitle: "Ventas Evento",
          middleware: "auth",
        },
      },
      {
        path: "/factura-ventas",
        name: "facturaventas",
        component: () => import("@/views/informes/facturasventa.vue"),
        meta: {
          pageTitle: "Factura Ventas",
          middleware: "auth",
        },
      },
      {
        path: "/detalleventas",
        name: "detalleventas",
        component: () => import("@/views/informes/detalleventas.vue"),
        meta: {
          pageTitle: "Detalle de Ventas",
          middleware: "auth",
        },
      },
      {
        path: "/detallemaraton",
        //name: "localidadesEventos",
        component: () => import("@/views/informes/detallemaraton.vue"),
        meta: {
          pageTitle: "Detalle  Maraton",
          middleware: "auth",
        },
      },
      {
        path: "/giras",
        name: "giras",
        component: () => import("@/views/giras/giras.vue"),
        meta: {
          pageTitle: "Todas las Giras",
          middleware: "auth",
        },
      },
      {
        path: "/creargira",
        //name: "localidadesEventos",
        component: () => import("@/views/giras/agregar.vue"),
        meta: {
          pageTitle: "Crear  Gira",
          middleware: "auth",
        },
      } ,
      {
        path: "/editargira/:id",
        //name: "localidadesEventos",
        component: () => import("@/views/giras/editar.vue"),
        meta: {
          pageTitle: "Editar  Gira",
          middleware: "auth",
        },
      } ,
      {
        path: "/eliminargira/:id",
        //name: "localidadesEventos",
        component: () => import("@/views/giras/confirmareliminar.vue"),
        meta: {
          pageTitle: "Eliminar  Gira",
          middleware: "auth",
        },
      } ,
      {
        path: "/insertrol",
        //name: "editEvento",
        component: () => import("@/views/roles/InsertRol.vue"),
        meta: {
          pageTitle: "Insertar Nuevo Rol",
          middleware: "auth",
        },
      },
      {
        path: "/listarroles",
        //name: "editEvento",
        component: () => import("@/views/roles/ListarRoles.vue"),
        meta: {
          pageTitle: "Listado de Roles",
          middleware: "auth",
        },
      },
      {
        path: "/editRol/:id",
        //name: "editEvento",
        component: () => import("@/views/roles/UpdateRol.vue"),
        meta: {
          pageTitle: "Editar Rol",
          middleware: "auth",
        },
      },
      {
        path: "/todaslaspreventas",
        name: "todaslaspreventas",
        component: () => import("@/views/preventas/todaslaspreventas.vue"),
        meta: {
          pageTitle: "Todas las Preventas",
          middleware: "auth",
        },
      },
      {
        path: "/crearpreventa",
        //name: "localidadesEventos",
        component: () => import("@/views/preventas/crearpreventa.vue"),
        meta: {
          pageTitle: "Crear  Preventa",
          middleware: "auth",
        },
      },
      {
        path: "/editarpreventa/:id",
        //name: "localidadesEventos",
        component: () => import("@/views/preventas/editarpreventa.vue"),
        meta: {
          pageTitle: "Crear  Preventa",
          middleware: "auth",
        },
      },
      {
        path: "/todasloscupones",
        name: "todasloscupones",
        component: () => import("@/views/cupones/todasloscupones.vue"),
        meta: {
          pageTitle: "Todas los Cupones",
          middleware: "auth",
        },
      },
      {
        path: "/crearcupon",
        //name: "localidadesEventos",
        component: () => import("@/views/cupones/crearcupon.vue"),
        meta: {
          pageTitle: "Crear  Cupon",
          middleware: "auth",
        },
      },
      {
        path: "/editarcupon/:id",
        //name: "localidadesEventos",
        component: () => import("@/views/cupones/editarcupon.vue"),
        meta: {
          pageTitle: "Editar   Cuponera",
          middleware: "auth",
        },
      },
      {
        path: "/vercuponera/:id",
        //name: "localidadesEventos",
        component: () => import("@/views/cupones/vercuponera.vue"),
        meta: {
          pageTitle: "Ver  Cuponera",
          middleware: "auth",
        },
      },
      {
        path: "/actualizardatos",
        name: "actualizardatos",
        component: () => import("@/views/usuarios/ActualizarDatos.vue"),
        meta: {
          pageTitle: "Actualizar Datos Usuario",
          middleware: "auth",
        },
      },
      {
        path: "/crearfuncioneseventos/:id",
        //name: "localidadesEventos",
        component: () => import("@/views/eventos/CrearFuncionesEvento.vue"),
        meta: {
          pageTitle: "Crear Funciones para el Evento",
          middleware: "auth",
        },
      },
      {
        path: "/updatefuncionesevento/:id",
        //name: "localidadesEventos",
        component: () => import("@/views/eventos/UpdateFuncionesEvento.vue"),
        meta: {
          pageTitle: "Editar Funciones",
          middleware: "auth",
        },
      },
      {
        path: "/funcionesactivasxevento/:id",
        //name: "localidadesEventos",
        component: () => import("@/views/eventos/FuncionesActivas.vue"),
        meta: {
          pageTitle: "Funciones Activas",
          middleware: "auth",
        },
      },
      {
        path: "/bannerdestacadoshome",
        name: "bannerdestacadoshome",
        component: () => import("@/views/eventos/BannerDestacadosHome.vue"),
        meta: {
          pageTitle: "Banner Destacados Home",
          middleware: "auth",
        },
      },
      {
        path: "/comunicados",
        name: "comunicados",
        component: () => import("@/views/comunicados/ComunicadosActivos.vue"),
        meta: {
          pageTitle: "Comunicados",
          middleware: "auth",
        },
      },
      {
        path: "/editComunicado/:id",
        //name: "editEvento",
        component: () => import("@/views/comunicados/EditarComunicado.vue"),
        meta: {
          pageTitle: "Editar Comunicado",
          middleware: "auth",
        },
      },
      {
        path: "/insertComunicado",
        name: "insertComunicado",
        component: () => import("@/views/comunicados/InsertComunicado.vue"),
        meta: {
          pageTitle: "Insertar Comunicado",
          middleware: "auth",
        },
      },
      {
        path: "/formularioaumentarsilleteria",
        name: "aumentaraforosilleteria",
        component: () => import("@/views/aforos/FormularioAumentarSilleteria.vue"),
        meta: {
          pageTitle: "Aumentar Aforo Silleteria",
          middleware: "auth",
        },
      },
      {
        path: "/formulariodisminuirsilleteria",
        name: "disminuiraforosilleteria",
        component: () => import("@/views/aforos/FormularioDisminuirSilleteria.vue"),
        meta: {
          pageTitle: "Disminuir Aforo Silleteria",
          middleware: "auth",
        },
      },
      {
        path: "/aliadosactuales",
        name: "listaraliados",
        component: () => import("@/views/aliados/AliadosActuales.vue"),
        meta: {
          pageTitle: "Listar Aliados",
          middleware: "auth",
        },
      },
      {
        path: "/editAliado/:id",
        //name: "editEvento",
        component: () => import("@/views/aliados/EditarAliado.vue"),
        meta: {
          pageTitle: "Editar Aliado",
          middleware: "auth",
        },
      },
      {
        path: "/insertAliado",
        name: "insertAliado",
        component: () => import("@/views/aliados/InsertAliado.vue"),
        meta: {
          pageTitle: "Insertar Aliado",
          middleware: "auth",
        },
      },
      {
        path: "/listarmenus",
        //name: "editEvento",
        component: () => import("@/views/menu/ListarMenus.vue"),
        meta: {
          pageTitle: "Lista de los Menus",
          middleware: "auth",
        },
      },
      {
        path: "/updatemenus",
        //name: "editEvento",
        component: () => import("@/views/menu/UpdateMenus.vue"),
        meta: {
          pageTitle: "Insertar Nuevo Menu",
          middleware: "auth",
        },
      },
      {
        path: "/updatepermisos",
        //name: "editEvento",
        component: () => import("@/views/permisos/UpdatePermisos.vue"),
        meta: {
          pageTitle: "Insertar Nuevos Permisos",
          middleware: "auth",
        },
      },
      {
        path: "/updatefuncionalidades",
        //name: "editEvento",
        component: () => import("@/views/menu/UpdateFuncionalidades.vue"),
        meta: {
          pageTitle: "Insertar Nuevos Funcionalidades",
          middleware: "auth",
        },
      },
      {
        path: "/asignarpermisos",
        //name: "editEvento",
        component: () => import("@/views/permisos/AsignarPermisos.vue"),
        meta: {
          pageTitle: "Asignar Permisos",
          middleware: "auth",
        },
      },
      {
        path: "/eliminarpermisos",
        //name: "editEvento",
        component: () => import("@/views/permisos/EliminarPermisos.vue"),
        meta: {
          pageTitle: "Desactivar Permisos",
          middleware: "auth",
        },
      },
      {
        path: "/cambiarimagenpuntoventa",
        //name: "editEvento",
        component: () => import("@/views/otros/CambiarImagenPuntoVenta.vue"),
        meta: {
          pageTitle: "Imagen Punto de Venta",
          middleware: "auth",
        },
      },
      {
        path: "/notificar-dian",
        //name: "editEvento",
        component: () => import("@/views/facturacion_electronica/notificar_dian.vue"),
        meta: {
          pageTitle: "Notificación DIAN",
          middleware: "auth",
        },
      },
      {
        path: "/centro-costos",
        //name: "editEvento",
        component: () => import("@/views/facturacion_electronica/centro_costos.vue"),
        meta: {
          pageTitle: "Gestionar Facturación Electrónica",
          middleware: "auth",
        },
      },
      {
        path: "/listado-novedades-fe",
        //name: "editEvento",
        component: () => import("@/views/facturacion_electronica/componentes/listado_novedades.vue"),
        meta: {
          pageTitle: "Novedades - Facturación Electrónica",
          middleware: "auth",
        },
      },
      {
        path: "/listado-ventas-notificadas-fe",
        //name: "editEvento",
        component: () => import("@/views/facturacion_electronica/componentes/listado_ventasnotificadas.vue"),
        meta: {
          pageTitle: "Ventas Notificadas - Facturación Electrónica",
          middleware: "auth",
        },
      },
      ///////////
      //////////
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"],
        },
      },
      
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        meta: {
          breadcrumbs: ["Pages", "Profile"],
        },
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
            meta: {
              pageTitle: "Projects",
            },
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
            meta: {
              pageTitle: "Campaigns",
            },
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
            meta: {
              pageTitle: "Documents",
            },
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
            meta: {
              pageTitle: "Connections",
            },
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
            meta: {
              pageTitle: "Activity",
            },
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
        meta: {
          pageTitle: "Horizontal",
          breadcrumbs: ["Pages", "Wizard"],
        },
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
        meta: {
          pageTitle: "Vertical",
          breadcrumbs: ["Pages", "Wizard"],
        },
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        meta: {
          breadcrumbs: ["Crafted", "Account"],
        },
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
            meta: {
              pageTitle: "Settings",
            },
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
        meta: {
          pageTitle: "Getting Started",
          breadcrumbs: ["Apps", "Customers"],
        },
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
        meta: {
          pageTitle: "Customers Listing",
          breadcrumbs: ["Apps", "Customers"],
        },
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
        meta: {
          pageTitle: "Customers Details",
          breadcrumbs: ["Apps", "Customers"],
        },
      },
      {
        path: "/apps/subscriptions/getting-started",
        name: "apps-subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
        meta: {
          pageTitle: "Getting Started",
          breadcrumbs: ["Apps", "Subscriptions"],
        },
      },
      {
        path: "/apps/subscriptions/subscription-list",
        name: "apps-subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
        meta: {
          pageTitle: "Getting Started",
          breadcrumbs: ["Apps", "Subscriptions"],
        },
      },
      {
        path: "/apps/subscriptions/add-subscription",
        name: "apps-subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
        meta: {
          pageTitle: "Add Subscription",
          breadcrumbs: ["Apps", "Subscriptions"],
        },
      },
      {
        path: "/apps/subscriptions/view-subscription",
        name: "apps-subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
        meta: {
          pageTitle: "View Subscription",
          breadcrumbs: ["Apps", "Subscriptions"],
        },
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
        meta: {
          pageTitle: "Calendar",
          breadcrumbs: ["Apps"],
        },
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Private Chat",
          breadcrumbs: ["Apps", "Chat"],
        },
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Group Chat",
          breadcrumbs: ["Apps", "Chat"],
        },
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
        meta: {
          pageTitle: "Drawer Chat",
          breadcrumbs: ["Apps", "Chat"],
        },
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
        meta: {
          pageTitle: "Invite Friends",
          breadcrumbs: ["Crafted", "Modals", "General"],
        },
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
        meta: {
          pageTitle: "View User",
          breadcrumbs: ["Crafted", "Modals", "General"],
        },
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
        meta: {
          pageTitle: "Upgrade Plan",
          breadcrumbs: ["Crafted", "Modals", "General"],
        },
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
        meta: {
          pageTitle: "Share And Earn",
          breadcrumbs: ["Crafted", "Modals", "General"],
        },
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
        meta: {
          pageTitle: "New Target",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
        meta: {
          pageTitle: "New Card",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
        meta: {
          pageTitle: "New Address",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
        meta: {
          pageTitle: "Create Api Key",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
        meta: {
          pageTitle: "Two Factory Auth",
          breadcrumbs: ["Crafted", "Modals", "Wizards"],
        },
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
        meta: {
          pageTitle: "Create App",
          breadcrumbs: ["Crafted", "Modals", "Wizards"],
        },
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
        meta: {
          pageTitle: "Create Account",
          breadcrumbs: ["Crafted", "Modals", "Wizards"],
        },
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
        meta: {
          pageTitle: "Lists",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
        meta: {
          pageTitle: "Statistics",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
        meta: {
          pageTitle: "Charts",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
        meta: {
          pageTitle: "Mixed",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
        meta: {
          pageTitle: "Tables",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
        meta: {
          pageTitle: "Feeds",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) { 
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
